/* Main Variables
*****
***** */
:root {
  --bg-gray: #e1e0e2;
  --white: #ffffff;
}

/* Abstract Classes
*****
***** */
.reset-a,
.reset-a:hover,
.reset-a:visited,
.reset-a:focus,
.reset-a:active {
  text-decoration: none;
  color: inherit;
  outline: 0;
  cursor: auto;
}
a, a:visited, a:hover, a:active {
  color: inherit;
}
.removePadding {
  padding: 0px !important;
}

.reactLazyLoaderBGimageSetter {
  background-size: cover;
  background-position: center top;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.border-radius-10 {
  border-radius: 10px;
}

/* MainContainer
*
*
*
*
*/
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

body {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 1px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 3px solid slategrey;
}

body {
  overflow-x: hidden;
}

.mainContentContainer {
  margin-top: 73px !important;
}

.basicLink {
  color: black;
}

.widthHundredPC {
  width: 100%;
}

.MuiContainer-root {
  padding: 0px !important;
}

/* HomePage
  *
  */
.categoryContainer {
  align-items: baseline;
  align-content: center;
  justify-content: space-around;
  white-space: normal;
}

.categoryItem {
  margin-top: 8px !important;
  text-align: center;
  font-size: 0.85rem;
  font-weight: 600;
  max-width: 80px !important;
  word-wrap: break-word;
}

.sliderTag {
  text-transform: capitalize;
  text-align: center;
  margin: 0 !important;
  padding: 12px;
  margin-top: 2px !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: var(--bg-gray);
}

/* VendorList Page
*
*
*/
.vendorListPageContainer {
  margin-top: 13px;
  margin-bottom: 58px;
  width: 100%;
}

.vendorListContentContainer {
  padding-left: 2%;
  padding-right: 2%;
  width: 96%;
}

.vendorListElementContainer {
  margin-top: 5px;
}

.vendorListElementLogo {
  padding: 1%;
}

.vendorListElementMText {
  padding-left: 1em;
}

.vendorListElementMGrid {
  height: 100%;
}

.vendorListElementLocality {
  padding-left: 2%;
}

.categoryDialogueListContainer {
  flex-direction: column;
  justify-content: space-around;
}

.categoryDialogueListElement {
  padding: 0.4em;
}

.MuiTab-root {
  font-size: 0.85em !important;
}

/* Category Dialogue
*
*
*/

.categoryDialogueHeaderContainer {
  position: relative;
}

.categoryDialogueBodyContainer {
  position: relative;
  padding: 2em !important;
}

/* VendorDetails Page
*
*
*/

/* OffersTab */
.voucherHeading {
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.6em;
  padding-left: 1em;
  width: 100%;
}


.vendorDetailsContainer {
  margin-top: 5rem !important;
  margin-bottom: 58px !important;
}

.imagesContainer {
  position: relative;
}

.headerImage {
  width: 100%;
}

.logoImage {
  height: 6em;
  width: 6em;
  position: absolute;
  left: 2em;
  bottom: 2em;
  border-radius: 10px;
}

.callButton {
  position: absolute;
  right: 1em;
  bottom: 1em;
  color: #ffffff;
  font-size: 40px !important;
}

.dialogueCallText {
  text-align: left;
}

.dialogueCallButton {
  text-align: right;
}

.callListDivider {
  padding: 1.5em 0em;
}

.locationDataContainer {
  padding: 2px;
}

.locationPinIcon {
  padding-left: 1em;
}

/* Location Dialogue*/

.locationDialogueContainer {
  /* padding-top: 1em; */
  overflow-x: hidden;
  /* background-color: #f15a75; */
  text-align: center;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;

}
.locationDialogueHeaderContainer {
  position: relative;
  margin: 0.2em !important;
  padding-left: 1em;
  padding-right: 1em;
}

.locationCloseButton {
  position: absolute;
  top: 0.7em;
  right: 0.2em;
  border: none;
  /* padding: 14px 14px; */
  color: #707070;
  background-color: #FFF;
}

.locationDialogueTitle {
  margin: 0.5em;
}

.locationOutletCount {
  background-color: #707070;
  color: #fff;
  padding: 0.2em 2em;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
.locationOutletCountFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.locationDialogueBodyContainer {
  position: relative;
  padding: 2em 0em !important;
}

/* Vouchers List */
.voucherListContainer {
  margin-top: 0.2rem;
  height: inherit;
  width: 100%;
}

.voucherIcon {
  width: 4rem;
  padding: 0.6rem;
}

.voucherTextContainer {}

.voucherTitle {
  font-size: 1rem;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  margin-bottom: 0.5rem;
}

/* DetailsTab */
.vendorAddressContainer {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: var(--bg-gray);
}

.detailsCityName {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.detailsVendorAddress {
  margin-top: 0;
}

.detailsVendorDesc {}

.detailsTabMap {}

/* VoucherDialogue
*
*
*/

.voucherContainer {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  overflow-x: hidden;
  /* background-color: #f15a75; */
  text-align: center;
}

.voucherHeaderContainer {
  position: relative;
  color: #fff;
}

.voucherCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 10px 14px;
  border-radius: 34px;
  color: #fff;
  background-color: #000;
}

.voucherLogoImage {
  height: 6em;
  width: 6em;
}

.voucherBodyContainer {
  background-color: #f1eff1;
  margin-top: 2em !important;
  border: 1px solid #fff;
  border-radius: 12px;
  padding: 1em 0em;
  background-image: url("./../vouch365Watermark.png");
  background-size: cover;
}

.voucherRedeemButton {
  border: none;
  padding: 10px 37px;
  border-radius: 12px;
  color: #fff;
  background-color: #000;
}

.voucherFooterContainer {
  font-weight: 100;
  font-size: small;
  color: #fff;
}

.pinInputTextFieldContainer {
  padding-top: 1em;
}

.deliverConfirmationContainer {

  background-color: #f1eff1;
  margin-top: 2em !important;
  border: 1px solid #fff;
  border-radius: 12px;
  padding: 2rem 0em;
}

.confirmationButton {

  border-radius: 25px !important;
  padding: 0.5em 3em !important;
}

/* .pinInputTextFieldContainer::-webkit-outer-spin-button,
.pinInputTextFieldContainer::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
/* Firefox */
/* .pinInputTextFieldContainer[type=number] {
  -moz-appearance: textfield; 
}

*/

.pinInputTextField {
  background-color: var(--bg-gray);
  border-radius: 5px;
}

.pinInputTextField2 {
  text-align: center;
}

.pinInputTextField:focus {
  border: none !important;
}

.MuiDialog-paperWidthMd {
  border-radius: 13px !important;
}

.voucherRefCodeContainer {
  margin: 2em 0.5em !important;
  padding: 0px 0.5rem;
  font-size: 1.03em;
  background-color: #ffce72;
}

.vcRefCodeHeader {
  width: 100%;
  margin-bottom: -0.8rem;
  margin-top: 1em;
  text-align: center;
  color: #fff;
}